/* App.css */
body{
  background-color: #eee;
  height: 100%;
  margin: 0;
}
.App {
  
  background-color: #222;
}

.app-container {
  padding: 5rem;
  min-height:100vh;
}

.app-header {
  text-align: center;
  margin-bottom: 30px;
}

.app-header h1 {
  font-family: 'Geist Mono', monospace;
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 2px;
  color: black;
}

.button-container {
  text-align: center;
}

button {
  font-family: 'Geist Mono', monospace;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px;
  border-radius: 50px;
  color: black;
  border: 2px solid black;
  background-color: white;
  box-shadow: none;
  transition: all 0.3s ease;
}

button:hover {
  background-color: black;
  color: white;
  transform: translateY(-2px);
}

button:focus {
  outline: none;
  box-shadow: none;
}

/* Modal Styles */
.modal-content {
  border-radius: 15px;
  border: 2px solid black;
}

.modal-header, .modal-footer {
  background-color: white;
}

.modal-body {
  background-color: white;
  color: black;
}

.modal-title {
  font-family: 'Geist Mono', monospace;
  font-weight: bold;
  font-size: 1.5rem;
}

button {
  font-family: 'Geist Mono', monospace;
  font-weight: bold;
  border-radius: 50px;
  box-shadow: none;
}

button:hover {
  background-color: black;
  color: white;
}

p{
  font-family: 'Geist Mono', monospace;
}
/* Glitch text styles */
.glitch-text {
  font-family: 'Geist Mono', monospace;
  font-size: inherit; /* Match the parent's font size */
  font-weight: bold;
  letter-spacing: inherit; /* Match parent spacing */
  color: white;
  display: inline; /* Ensure it behaves like inline text */
  position: relative;
  animation: glitch 5s infinite; /* Subtle, slow glitch effect */
  vertical-align: baseline; /* Aligns with baseline of surrounding text */
}

/* Glitch effect */
@keyframes glitch {
  0%, 100% {
    text-shadow: none;
  }
  20% {
    text-shadow: 1px 1px 0 #ff005a, -1px -1px 0 #00d1ff;
  }
  40% {
    text-shadow: -2px 0px 0 #ff005a, 2px 0px 0 #00d1ff;
  }
  60% {
    text-shadow: 0px 2px 0 #00d1ff, 0px -2px 0 #ff005a;
  }
  80% {
    text-shadow: -1px -1px 0 #00d1ff, 1px 1px 0 #ff005a;
  }
}

/* Add subtle scanlines to glitch-text only */
.glitch-text::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Keep the scanlines in the background */
  pointer-events: none;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 1px,
    transparent 1px,
    transparent 3px
  );
  animation: scanline 1s linear infinite; /* Subtle, slower scanlines */
}

/* Scanline animation */
@keyframes scanline {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 2px;
  }
}



/* Glitch text styles */
.glitch-text-two {
 
  font-family: 'Geist Mono', monospace;
  font-size: inherit; /* Match the parent's font size */
  letter-spacing: inherit; /* Match parent spacing */
  color: black;
  display: block; /* Ensure it behaves like inline text */
  position: relative;
  text-align: center; /* Aligns with baseline of surrounding text */
}

.lights-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lights-section {
  padding: 20px;
  border: 3px solid #000;
  border-radius: 15px;
  text-align: center;
}

.light-box {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.light-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 10px;
}

.black {
  background-color: black;
}

.orange {
  background-color: orange;
}

.green {
  background-color: green;
}

button:disabled {
  cursor: not-allowed;
}

.lyles{
  text-align: center;
  font-family: 'Geist Mono', monospace;
  margin-top: 2rem;
  color: white;
}