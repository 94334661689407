.mp3-player {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
  }
  
  .mp3-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .play-pause-btn {
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
  
  .progress-container {
    flex-grow: 1;
    margin-left: 15px;
    position: relative;
    height: 6px;
    cursor: pointer; /* Pointer cursor on progress bar */
  }
  
  .progress-line {
    width: 100%;
    height: 6px;
    background-color: #fff;
    border-radius: 3px;
    position: relative;
  }
  
  .progress-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: -3px;
    transition: left 0.1s ease;
    cursor: pointer; /* Pointer cursor when dragging */
  }
  .song-title{
    margin-top:3rem;
    text-align: center;
    font-family: 'Geist Mono', monospace;
    color: white;
  }